.maintpanel > .rs-panel-heading {
  font-family: var(--font-body);
  font-size: 1.5rem;
  font-weight: 200;
  background-color: var(--grey1);
}

.header-middle > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
