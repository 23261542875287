.unread-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.unread-panel > .rs-panel-heading {
  width: 100%;
  text-align: center;
}

.unread-panel > .rs-panel-body {
  flex-grow: 1;
  display: flex;
}

.unread-count {
  padding: 30px;
  font-size: 8rem;
  font-weight: 700;
  color: var(--grey3);
}

.unread-count:focus,
.unread-count:active,
.unread-count:hover {
  text-decoration: none;
  color: var(--grey3);
}
