.header-wrapper {
  position: fixed;
  width: 100%;
  z-index: 999;
}

.rs-navbar-nav .rs-dropdown > .rs-dropdown-toggle {
  padding: 8px 0px 8px 0px;
}

.rs-divider-vertical {
  height: 2.3em;
  margin: 11px;
}

.header-dropdown .rs-dropdown-menu .rs-dropdown-item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-dropdown .rs-dropdown-menu .rs-dropdown-item-content .rs-btn-toggle-sm {
  margin-right: 10px;
}

.rs-navbar-nav .rs-nav-item > .rs-nav-item-content:first-of-type {
  display: flex;
}

.rs-navbar-nav .rs-nav-item a {
  font-size: 16px;
}
